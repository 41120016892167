
import { defineComponent, PropType, ref, onMounted, computed, watch } from 'vue'
import {fetchImg} from '@/utils/fetchImg' 
import store from '@/store'

export default defineComponent({
  name: "Nav",
  props:{
      img: {
          type:String as PropType<string>
      },
      isVideo:{
          default:true
      },
      _style: {
          default:'',
          type:String as PropType<string>
      },
      loop: {
          default:false,
          type:Boolean as PropType<boolean>
      },
      autoplay: {
          default:false,
          type:Boolean as PropType<boolean>
      },
      controls: {
          default:false,
          type:Boolean as PropType<boolean>
      },
      muted: {
          default:false,
          type:Boolean as PropType<boolean>
      },
      _direction: {default:'right'},
      decorColor: {default:-1}
  },
  components:{

  },
  setup(){

    const colors = [
        '#FFE46D',
        '#B8A0EC',
        '#33C5B9',
        '#3E74D1',
        '#45B53C',
        '#B8A0EC',
    ]

    const shuffle = (array:any[]) => {
        let currentIndex = array.length,  randomIndex;

        // While there remain elements to shuffle...
        while (currentIndex != 0) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }

        return array;
    }

    const randomColors = ref(colors as string[]) 
    const randomIndex = ref(0)

    onMounted(()=>{
        randomColors.value = shuffle(colors)
        randomIndex.value = Math.floor(Math.random() * 12)
    })

    return { 
        randomColors,
        randomIndex,
        store
    }
  },
})
