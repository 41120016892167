
import { defineComponent, PropType, ref, onMounted, computed, watch, onBeforeUnmount, nextTick } from 'vue'
import scroll from '@/scroll'

export default defineComponent({
  props:{
  },
  components:{

  },
  setup(props){
        const dummyElem = document.createElement('div')
        const tiger = ref(dummyElem)

        const transform = ref('')
        const direction = ref('scaleX(1) ')

        const headerHeight = 120
        const tigerHeight = 100


        onMounted(()=>{
            const stairs = Array.from( document.querySelectorAll('[marker="tiger"]') )

            const tigerRect = tiger.value.getBoundingClientRect()

            let storeStair = undefined as undefined | any

            let tigerLock = false
            let lastScroll = 0
            let lastLeft = 0
            let startFacingRight = true

            let rotateDegree = 0
            let hopUp = true

            const reposition = () => {

                const currStair = 
                    stairs.find(x=>x.getBoundingClientRect().top > headerHeight + tigerHeight) ||
                    stairs[stairs.length-1]

                if(!currStair){return}

                // handle vertical location
                const currStairRect = currStair.getBoundingClientRect()
                tiger.value.style.top = `${currStairRect.top - 100}px`

                
                // handle horizontal location
                // start on which end
                const upperStair = stairs[stairs.indexOf(currStair) - 1]
                const underStair = stairs[stairs.indexOf(currStair) + 1]
                if(currStair != storeStair){
                    if(stairs.indexOf(currStair) == stairs.length - 1){
                        startFacingRight = currStairRect.left + currStairRect.width/2 > upperStair.getBoundingClientRect().left
                    }else{
                        startFacingRight = currStairRect.left + currStairRect.width/2 < underStair.getBoundingClientRect().left
                    }
                }
                // traveled percentage
                const percentDone = stairs.indexOf(currStair) == 0 ? 
                Math.max(0, 
                    Math.min(
                        (scroll.value + headerHeight - tigerHeight)/
                        (scroll.value + currStairRect.top - headerHeight - tigerHeight), 
                    1)
                ) : 1 - 
                Math.max(0, 
                    Math.min(
                        (currStairRect.top - headerHeight - tigerHeight)/
                        (currStairRect.top - upperStair.getBoundingClientRect().top), 
                    1)
                )
                const totalWidth = currStairRect.width

                const currLeft = startFacingRight ? 
                    currStairRect.left + percentDone * totalWidth : 
                    currStairRect.left + (1-percentDone) * totalWidth - tigerRect.width
                tiger.value.style.left = `${currLeft}px` 

                if(currStair!= storeStair){
                    storeStair = currStair
                }

                // handle direction
                // roll
                if(currLeft > lastLeft) {
                    direction.value = 'scaleX(-1) '
                    rotateDegree = (rotateDegree - 10) % 360
                }else{
                    direction.value = 'scaleX(1) '
                    rotateDegree = (rotateDegree - 10) % 360
                }
                // hop
                // if(currLeft > lastLeft) {
                //     direction.value = 'scaleX(-1) '
                //     rotateDegree = hopUp ? (rotateDegree + 5) % 50 : (rotateDegree - 5) % 50
                //     if(rotateDegree == 60 || rotateDegree == 0 ) hopUp = !hopUp
                // }else{
                //     direction.value = 'scaleX(1) '
                //     rotateDegree = hopUp ? (rotateDegree + 5) % 50 : (rotateDegree - 5) % 50
                //     if(rotateDegree == 60 || rotateDegree == 0 ) hopUp = !hopUp
                // }
                lastLeft = currLeft

                const rotate = `rotate(${rotateDegree + 20}deg)` 

                transform.value = `transform:${direction.value} ${rotate}`
            }
            reposition()

            watch(()=>scroll.value, reposition)
        })

        return { 
            transform,
            direction,
            tiger
        }
  },
})
