import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-616ee024"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "outer"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "img_wrap_2_1" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "img_wrap_2_2" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "img_wrap_3_1" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "img_wrap_3_2" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "img_wrap_3_3" }
const _hoisted_12 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.img)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.img.length==1)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "timage_wrap oneImg",
              style: _normalizeStyle(_ctx._style)
            }, [
              _createElementVNode("img", {
                marker: "tiger",
                src: _ctx.img[0] || _ctx.store.state.defaultHeadImg,
                class: "_timage",
                style: _normalizeStyle(_ctx.imageStyle)
              }, null, 12, _hoisted_2),
              (_ctx.randomIndex % 4 == 0)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "decor_vert decor1_1",
                    style: _normalizeStyle(`background-color:${_ctx.decorColor>-1?_ctx.randomColors[_ctx.decorColor]:_ctx.randomColors[0]}`)
                  }, null, 4))
                : _createCommentVNode("", true),
              (_ctx.randomIndex % 4 == 0)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "decor_hori decor1_2",
                    style: _normalizeStyle(`background-color:${_ctx.decorColor>-1?_ctx.randomColors[_ctx.decorColor]:_ctx.randomColors[0]}`)
                  }, null, 4))
                : _createCommentVNode("", true),
              (_ctx.randomIndex%4==1)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: "decor_vert decor1_3",
                    style: _normalizeStyle(`background-color:${_ctx.decorColor>-1?_ctx.randomColors[_ctx.decorColor]:_ctx.randomColors[0]}`)
                  }, null, 4))
                : _createCommentVNode("", true),
              (_ctx.randomIndex%4==1)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 3,
                    class: "decor_hori decor1_4",
                    style: _normalizeStyle(`background-color:${_ctx.decorColor>-1?_ctx.randomColors[_ctx.decorColor]:_ctx.randomColors[0]}`)
                  }, null, 4))
                : _createCommentVNode("", true),
              (_ctx.randomIndex%4==2)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 4,
                    class: "decor_vert decor1_5",
                    style: _normalizeStyle(`background-color:${_ctx.decorColor>-1?_ctx.randomColors[_ctx.decorColor]:_ctx.randomColors[0]}`)
                  }, null, 4))
                : _createCommentVNode("", true),
              (_ctx.randomIndex%4==3)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 5,
                    class: "decor_vert decor1_6",
                    style: _normalizeStyle(`background-color:${_ctx.decorColor>-1?_ctx.randomColors[_ctx.decorColor]:_ctx.randomColors[0]}`)
                  }, null, 4))
                : _createCommentVNode("", true)
            ], 4))
          : _createCommentVNode("", true),
        (_ctx.img.length==2)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "timage_wrap twoImg",
              style: _normalizeStyle(_ctx._style)
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("img", {
                  marker: "tiger",
                  src: _ctx.img[0] || _ctx.store.state.defaultHeadImg,
                  class: "_timage_2_1",
                  style: _normalizeStyle(_ctx.imageStyle)
                }, null, 12, _hoisted_4),
                _createElementVNode("div", {
                  class: "decor_vert decor2_1",
                  style: _normalizeStyle(`background-color:${_ctx.decorColor>-1?_ctx.randomColors[_ctx.decorColor]:_ctx.randomColors[0]}`)
                }, null, 4)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("img", {
                  marker: "tiger",
                  src: _ctx.img[1] || _ctx.store.state.defaultHeadImg,
                  class: "_timage_2_2",
                  style: _normalizeStyle(_ctx.imageStyle)
                }, null, 12, _hoisted_6),
                (_ctx.randomIndex % 2 == 0)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "decor_vert decor2_2",
                      style: _normalizeStyle(`background-color:${_ctx.decorColor>-1?_ctx.randomColors[_ctx.decorColor]:_ctx.randomColors[0]}`)
                    }, null, 4))
                  : _createCommentVNode("", true),
                (_ctx.randomIndex % 2 == 0)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: "decor_hori decor2_3",
                      style: _normalizeStyle(`background-color:${_ctx.decorColor>-1?_ctx.randomColors[_ctx.decorColor]:_ctx.randomColors[0]}`)
                    }, null, 4))
                  : _createCommentVNode("", true),
                (_ctx.randomIndex % 2 == 1)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      class: "decor_vert decor2_4",
                      style: _normalizeStyle(`background-color:${_ctx.decorColor>-1?_ctx.randomColors[_ctx.decorColor]:_ctx.randomColors[0]}`)
                    }, null, 4))
                  : _createCommentVNode("", true)
              ])
            ], 4))
          : _createCommentVNode("", true),
        (_ctx.img.length>=3)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: "timage_wrap threeImg",
              style: _normalizeStyle(_ctx._style)
            }, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("img", {
                  marker: "tiger",
                  src: _ctx.img[0] || _ctx.store.state.defaultHeadImg,
                  class: "_timage_3_1",
                  style: _normalizeStyle(_ctx.imageStyle)
                }, null, 12, _hoisted_8),
                _createElementVNode("div", {
                  class: "decor_vert decor3_1",
                  style: _normalizeStyle(`background-color:${_ctx.decorColor>-1?_ctx.randomColors[_ctx.decorColor]:_ctx.randomColors[0]}`)
                }, null, 4)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("img", {
                  src: _ctx.img[1] || _ctx.store.state.defaultHeadImg,
                  class: "_timage_3_2",
                  style: _normalizeStyle(_ctx.imageStyle)
                }, null, 12, _hoisted_10),
                _createElementVNode("div", {
                  class: "decor_vert decor3_2",
                  style: _normalizeStyle(`background-color:${_ctx.decorColor>-1?_ctx.randomColors[_ctx.decorColor]:_ctx.randomColors[1]}`)
                }, null, 4)
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("img", {
                  marker: "tiger",
                  src: _ctx.img[2] || _ctx.store.state.defaultHeadImg,
                  class: "_timage_3_3",
                  style: _normalizeStyle(_ctx.imageStyle)
                }, null, 12, _hoisted_12),
                _createElementVNode("div", {
                  class: "decor_hori decor3_3",
                  style: _normalizeStyle(`background-color:${_ctx.decorColor>-1?_ctx.randomColors[_ctx.decorColor]:_ctx.randomColors[2]}`)
                }, null, 4)
              ])
            ], 4))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}