import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ef16134"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "outer"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.img)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "timage_wrap oneImg",
          style: _normalizeStyle(_ctx._style)
        }, [
          _createElementVNode("img", {
            src: _ctx.img || _ctx.store.state.defaultHeadImg,
            class: "_timage",
            style: _normalizeStyle(_ctx._style)
          }, null, 12, _hoisted_2),
          (_ctx.randomIndex % 4 == 0)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "decor_vert decor1_1",
                style: _normalizeStyle(`background-color:${_ctx.decorColor>-1?_ctx.randomColors[_ctx.decorColor]:_ctx.randomColors[0]}`)
              }, null, 4))
            : _createCommentVNode("", true),
          (_ctx.randomIndex % 4 == 0)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "decor_hori decor1_2",
                style: _normalizeStyle(`background-color:${_ctx.decorColor>-1?_ctx.randomColors[_ctx.decorColor]:_ctx.randomColors[0]}`)
              }, null, 4))
            : _createCommentVNode("", true),
          (_ctx.randomIndex%4==1)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: "decor_vert decor1_3",
                style: _normalizeStyle(`background-color:${_ctx.decorColor>-1?_ctx.randomColors[_ctx.decorColor]:_ctx.randomColors[0]}`)
              }, null, 4))
            : _createCommentVNode("", true),
          (_ctx.randomIndex%4==1)
            ? (_openBlock(), _createElementBlock("div", {
                key: 3,
                class: "decor_hori decor1_4",
                style: _normalizeStyle(`background-color:${_ctx.decorColor>-1?_ctx.randomColors[_ctx.decorColor]:_ctx.randomColors[0]}`)
              }, null, 4))
            : _createCommentVNode("", true),
          (_ctx.randomIndex%4==2)
            ? (_openBlock(), _createElementBlock("div", {
                key: 4,
                class: "decor_vert decor1_5",
                style: _normalizeStyle(`background-color:${_ctx.decorColor>-1?_ctx.randomColors[_ctx.decorColor]:_ctx.randomColors[0]}`)
              }, null, 4))
            : _createCommentVNode("", true),
          (_ctx.randomIndex%4==3)
            ? (_openBlock(), _createElementBlock("div", {
                key: 5,
                class: "decor_vert decor1_6",
                style: _normalizeStyle(`background-color:${_ctx.decorColor>-1?_ctx.randomColors[_ctx.decorColor]:_ctx.randomColors[0]}`)
              }, null, 4))
            : _createCommentVNode("", true)
        ], 4)
      ]))
    : _createCommentVNode("", true)
}